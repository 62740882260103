const PARRAFOS = {
    PARRAFO_CANCELAR_CUENTA: `Si ho desitges pots cancel·lar el teu compte. Si ho fas perdràs l'accés als teus llibres de l'edifici. Aquesta acció no es pot desfer.`,
    PARRAFO_DATOS_NO_CAMBIAR: `Les següents dades ja no es podran canviar una vegada s'hagi creat`,
    
    PARRAFO_PASO_1_TITULO: `Edificis d'habitatges de nova construcció o provinents d'una gran rehabilitació`,
    PARRAFO_PASO_1_PARRAFO: `El llibre de l'edifici s'ha de formalitzar abans de la primera venda o transmissió dels habitatges d'un edifici de nova construcció o provinent d'una gran rehabilitació.`,
    PARRAFO_PASO_2_TITULO: `Edificis del sector terciari`,
    PARRAFO_PASO_2_PARRAFO: `El llibre de l'edifici s'ha de formalitzar abans de la primera venda o transmissió si l'edifici és de nova construcció o si s'ha fet una gran rehabilitació.`,
    PARRAFO_PASO_3_TITULO: `Edificis d'habitatges existents`,
    PARRAFO_PASO_3_PARRAFO: `Els propietaris dels immobles l’ús principal dels quals sigui residencial tenen l’obligació de conservar-los i rehabilitar-los de manera que sempre estiguin en condicions d’ús efectiu i adequat.`,
    PARRAFO_REGISTRATE: `Si no disposeu d'un nom d'usuari i contrasenya us heu de registrar per accedir-hi`,
    PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR: 'No hi ha dades per mostrar',
    PARRAFO_IMAGENES_FORMATO_JGP_PNG: `Les imatges poden estar en format JPG o PNG. És preferible que no superin els 5MB.`,
    PARRAFO_IMAGENES_FORMATO_JGP_PNG_MB: `Les imatges poden estar en format JPG o PNG. És preferible que no superin els`,
    PARRAFO_ESCRIBA_NIF_USUARIO: `Escriviu el NIF de l'usuari`,
    PARRAFO_DESEA_ELIMINAR_USUARIO: `Esteu segur que voleu suprimir aquest usuari?`,
    PARRAFO_USUARIO_YA_REGISTRADO_EDIFICIO: `L'usuari ja està registrat a l'edifici`,

    PARRAFO_DESEA_RECHAZAR_ACCESO_EDIFICIO: `¿Segur que voleu rebutjar l'accés a aquest edifici?`,
    PARRAFO_REF_CATASTRAL_NO_VALIDA: 'La referència cadastral no és vàlida',
    PARRAFO_HA_RECHAZADO_ACCESO_EDIFICIO_CONTACTE: `Heu rebutjat l'accés a l'edifici`,
    PARRAFO_NECESITAS_REGISTRAR_DATOS_FACTURACION: 'Necessites registrar les teves dades de facturació per poder continuar',
    PARRAFO_TITULO_LISTADO_CONVENIOS: `Col·legis professionals i organitzacions en conveni`,
    PARRAFO_NAVEGUE_ARBOL_SELECCIONE: `Navegueu per l'arbre i seleccioneu un element`,
    PARRAFO_ARRASTRA_SUELTA_FICHEROS_CLIC: `Arrossega i deixa anar fitxers aquí, o fes clic per seleccionar fitxers`,
    PARRAFO_PROPUESTA_MEDIDAS_MEJORA: 'En aquest punt s’han d’incloure les diferents propostes que es plantegen per millorar les prestacions detectades, indicant una solució',
    PARRAFO_VENTAJAS_OBTENIDAS_MEJORA: 'En aquesta secció s’indicaran els beneficis pels usuaris, obtinguts amb les propostes de les mesures de millora plantejades',
    PARRAFO_COSTE_ECONOMICO_MEJORA: 'S’ha d’informar el cost (escollint una opció del llistat), que suposen cadascuna de les propostes de millora plantejades, segons el següent grau de quanties',
    PARRAFO_DIFICULTAD_EJECUCION_MEJORA: 'En aquest apartat s’ha d’indicar (escollint una opció del llistat), el nivell de complexitat que suposa l’execució de la mesura de millora, segons la següent categoria, raonant la situació concreta',
    PARRAFO_DESEA_ELIMINAR_PROPUESTA: `Esteu segur que voleu eliminar aquesta proposta?`,

    PARRAFO_DESEA_AGREGAR_ELEMENTOS_SELECCIONADOS_A_DEFICIENCIAS: `Esteu segur que voleu afegir els elements seleccionats a Deficiències?`,
    PARRAFO_ADJUNTAR_IMAGENES_CREAR_DEFICIENCIA: `Per poder adjuntar imatges, cal crear l'actuació`,
    PARRAFO_NOTA_EMPRESA_PROFESIONAL: `NOTA: aquestes dades són identificatives de l'empresa o professional responsable del Llibre de l'Edifici, les dades i la signatura de l'autor/s del document s'informen a l'apartat corresponent.`,
    PARRAFO_DESEA_ELIMINAR_ELEMENTO: `Esteu segur que voleu suprimir aquest element?`,
    PARRAFO_DESEA_ELIMINAR_ACTIVIDAD: `Esteu segur que voleu suprimir aquesta activitat?`,
    PARRAFO_ADJUNTAR_CREAR_ANOTACION: `Per poder adjuntar imatges o documents, cal crear l'anotació`,
    PARRAFO_DESEA_DESMARCAR_MEJORA: `Esteu segur que voleu desmarcar aquesta millora?`,
    PARRAFO_GENERANDO_DOCUMENTO_ESPERE: `S'està generant el document, si us plau espereu`,
    PARRAFO_PROYECTO_SIN_FASES_AGREGAR_CONTINUAR: `Aquest projecte no té fases, afegiu una fase per continuar`,
    PARRAFO_PERIODO_SIN_FASES_AGREGAR_CONTINUAR: `Aquest període no té fases, afegiu una fase per continuar`,
    PARRAFO_EDIFICIO_SIN_PERIODOS_AGREGAR_CONTINUAR: `Aquest edifici no té períodes, afegiu un període per continuar`,
    PARRAFO_TEST_ENERGETICO: `Eina que ajuda a establir les prioritats per planificar les millores que s'incorporaran a l'edifici per reduir la demanda energètica i les emissions de CO₂.`,
    PARRAFO_MARKETCONS: `Marketplace per a la reutilització de productes de la construcció presents en els edificis existents.`,
    PARRAFO_CONSTRULEX: `Aplicació informàtica per a obtenir informació de la legislació i reglamentació aplicable en obres d’edificació.`,
    PARRAFO_CONSTRUDOC: `Aplicació informàtica per a generar documents tècnics per a intervencions en edificació: documentació de projecte, documentació de seguretat i salut i documentació de qualitat.`,
    PARRAFO_TEST_ALUMINOSO: `El test del ciment aluminós és un servei creat per determinar si una mostra de formigó conté o no ciment aluminós.`,
    PARRAFO_TEST_RADON: `El Test Radó és un mètode senzill a l’abast de qualsevol persona que vulgui conèixer la concentració de radó al seu edifici, així com per a professionals que vulguin donar compliment normatiu als seus projectes d’edificació.`,
    PARRAFO_PRUEBAS_DE_LABORATORIO: `Assaigs i proves de laboratori àgils i estandarditzats, sobres les característiques tècniques més habituals requerides als productes de la construcció, per recolzar la tasca dels professionals de l’arquitectura tècnica`,
    PARRAFO_APARATOS_DE_MEDIDA: `Empreses del sector que ofereixen la compra o el lloguer d’eines i aparells de mesura amb descomptes.`,
    PARRAFO_PORTAL_ITE: `Portal Generador d’informes d’inspecció tècnica dels edificis (IITE) segons model normalitzat generat amb l’eina telemàtica habilitada per l’Agència de l’Habitatge de Catalunya.`,
    PARRAFO_SEGURO_SALIR_CAMBIOS_SIN_GUARDAR: `Hi ha canvis sense desar. Esteu segur que voleu sortir?`,
    PARRAFO_FICHAS_DE_CAMPO: `Document complementari per a estudiar el potencial de millores en edificis  existents en la fase d'inspecció`,
    PARRAFO_CREAR_MEDIDA_COMENTARIO: `En comentaris es pot indicar, entre altres aspectes, si la millora assoleix els nivells establerts per a edificis nous a la reglamentació, si la mesura o conjunts de mesures proposades tenen en compte la forma d'ocupació i l'ús de l'espai, així com la pròpia participació de les persones usuàries, etc.`,
    PARRAFO_PRECIOS_INCLUYEN_IVA: `Els preus inclouen l'IVA`,
    PARRAFO_USUARIOS_CONVENIO_COMPRA: `Els usuaris en conveni es validen en el moment de la compra`,
    PARRAFO_CONSULTA_CONVENIOS: `Consulta els col·legis professionals i organitzacions amb conveni`,
    PARRAFO_LEER_ACEPTAR_CONDICIONES_COMPRA: `per llegir i acceptar les nostres condicions generals i particulars en el procés de compra`,
    PARRAFO_SELECCIONE_ACTIVIDAD_IMPORTAR: 'Seleccioneu una activitat per importar i completeu les dades requerides',
    PARRAFO_APROBACION_SISTEMA_FINANCIERO: 'Aprovació del sistema de finançament',
    PARRAFO_INDICAR_FECHA_JUNTA: 'Indicar la data de la junta de la comunitat de propietaris o altra',
    PARRAFO_TITULO_AVISO_LEGAL: 'Avís legal i política de privadesa',
    PARRAFO_LEER_ACEPTAR_CONDICIONES: 'per llegir i acceptar la nostra política de privadesa',
    PARRAFO_MEDIDAS_SELECCIONADAS: 'Només les mesures seleccionades seran visibles en assignar les fases',
    PARRAFO_NIF_ASOCIADO_EXISTENTE: 'Aquest NIF ja està associat a un compte',
    PARRAFO_INICIE_SESION_RESTABLEZCA_CONTRASENIA: `Inicieu sessió o restabliu la contrasenya en cas que l'hagi oblidada`,
    PARRAFO_COLEGIADO_CATEB_NO_REGISTRARSE: `Si ets un col·legiat del Cateb, no cal registrar-se. Pots accedir directament amb les teves dades de col·legiat/da`,
    PARRAFO_SELECCIONE_ROLES_USUARIO: `Seleccioneu perfils per a l'usuari`,
    P_SUSCRIPCION_PREAVISO: `La subscripció es pot donar de baixa amb un preavís de 30 dies`,
    PARRAFO_DESEA_ELIMINAR_EDIFICIO: `Esteu segur que voleu suprimir l'edifici?`,
    PARRAFO_DESEA_DAR_BAJA_SUSCRIPCION_EDIFICIO: `Esteu segur que voleu donar de baixa la subscripció de l'edifici?`,
    P_EMPRESAS_COLABORADORAS: 'Coneix les principals empreses de productes i serveis per a la gestió del manteniment.',
    P_MONITORIZACION: `Eina d’anàlisis del comportament real de l'edifici mitjançant sensors col·locats in-situ.`,
    P_DOMOTICA_INMOTICA: 'Tecnologia aplicada al control i a l’automatització intel·ligent dels edificis.',
    P_ERROR_SINCRONIZACION_CATASTRO: 'Hi ha hagut un error en sincronitzar les dades de cadastre, intenteu-ho més tard.',
    P_ALTA_PRODUCTO_REPRESENTANTE_EDIFICIO: `L'alta del producte la fa la propietat o representant legal de la propietat de l'edifici.`,
    P_DATOS_ADMIN_LICENCIAS: 'S’hi han de fer constar les llicències en què s’empara la construcció de l’edifici i els documents que faculten l’ocupació general de l’edifici, amb la comunicació prèvia que, en el seu cas, s’hagi presentat a l’Ajuntament, així com les llicències relatives a instal·lacions comunes amb especificació de la data de concessió o sol·licitud.',
    P_DATOS_ADMIN_OBRANUEVA: 'S’hi han de fer constar el nom dels fedataris públics que han atorgat l’escriptura de declaració d’obra nova, el número de protocol i la data; també es faran constar les dades d’inscripció en el Registre de l’escriptura de declaració d’obra nova.',
    P_DATOS_ADMIN_PROPIEDAD: 'S’hi ha de fer constar el règim de propietat de l’edifici i, si escau, les dades de les escriptures de divisió en propietat horitzontal. També es farà constar la data d’aprovació dels estatuts de la propietat.',
    P_DATOS_ADMIN_ESPECIAL: 'Cal fer constar els règims especials de protecció oficial que, si escau, tingui l’edifici, amb expressió del document acreditatiu.',
    P_DATOS_ADMIN_CARGAS: 'S’hi han de fer constar les càrregues que, si escau, suporta l’edifici i les seves entitats jurídiques amb expressió dels documents que les acrediten.',
    P_DATOS_ADMIN_GAR_PROMOTOR: 'La garantia atorgada pels promotors que cobreixi la reparació dels defectes de la construcció i dels danys que en deriven directament sobre l’edifici d’habitatges, quan aquesta sigui exigible. En aquest apartat s’han de detallar el venciment i la referència de la pòlissa o altre document acreditatiu de l’atorgament de la garantia.',
    P_DATOS_ADMIN_GAR_INSTALACIONES: 'Les garanties específiques de les instal·lacions. En aquest apartat s’han de detallar aquelles instal·lacions d’elements comuns de l’edifici que tenen una garantia, amb expressió del seu venciment i el nom del garant.',
    P_DATOS_ADMIN_GAR_CONSTRUCTOR: 'La garantia atorgada pels constructors que cobreixi la reparació dels defectes de la construcció i dels danys que en deriven directament sobre l’edifici d’habitatges, quan aquesta sigui exigible. En aquest apartat s’han de detallar el venciment i la referència de la pòlissa o altre document acreditatiu de l’atorgament de la garantia.',
    P_CUMPLIMENTAR_EN_CASO_DISPONGA_INFORMACION: `Emplenar en cas que es disposi d'aquesta informació`,
    P_CONSULTA_TECNICOS: `Consulta els tècnics de capçalera especialistes en gestió del manteniment`,
    P_DESCARGUE_PLANTILLA_SEPA: `Descarregueu la plantilla del document SEPA, completeu les dades i pugeu el document aquí.`,
    P_PARA_REESTABLECER_CONTRASENIA: `Per restablir la contrasenya, cal que ens proporcioneu el vostre NIF.`,
    P_SI_ES_COLEGIADO_CATEB: `Si és un col·legiat Cateb, ha de restablir la contrasenya a`,
    P_PUEDE_CONTINUAR_TRAMITE_EDIFICIO: `Podeu continuar amb el tràmit d'alta de l'edifici, però no es podrà fer la gestió del manteniment del mateix fins que no tinguin donat d'alta aquest perfil.`,
    P_PROPIEDAD_CONTRATADO_TECNICO: `La propietat té contractat tècnic de capçalera especialista en gestió del manteniment?`,
    P_COSTE_ANUAL_DEPENDERA_ENTIDADES: `El cost anual de LED gestió dependrà del nombre d'entitats jurídiques que conformin l'edifici. Les entitats que s'indiquin en aquest apartat són les que apareixeran en el seu edifici virtual.`,
    P_HERRAMIENTA_DIGITAL_REDACCION_CONSTRUCCION: `Eina digital per a la redacció del Llibre de l'edifici de nova construcció o gran rehabilitació (per a edificis residencials i terciaris) i també el Llibre de l'edifici existent (per a edifici residencials unifamiliars i plurifamiliars).`,
    P_HERRAMIENTA_DIGITAL_GESTION_RESIDENCIALES: `Eina digital de gestió integral dels edificis residencials en temps real que permet la interoperativitat entre usuaris per reduir costos, revalorar el patrimoni i optimitzar el cicle de vida útil dels edificis`,
    P_HERRAMIENTA_DIGITAL_SOPORTE_A: `l'eina digital de suport a la`,
    P_REDACCION_LIBRO_EDIFICIO: `redacció del llibre de l'edifici`,
    P_TIPOS_EDIFICIOS_FORMALIZAR: `Quins tipus d'edificis es poden formalitzar amb aquesta eina?`,
    P_EDIFICIOS_VIVIENDAS_CONSTRUCCION: `Edificis d'habitatges de nova construcció o provinents d'una gran rehabilitació`,
    P_EDIFICIOS_SECTOR_TERCIARIO: `Edificis del sector terciari`,
    P_EDIFICIOS_VIVIENDAS_EXISTENTES: `Edificis d'habitatges existents`,
    P_TIPOS_EDIFICIOS_GESTIONAR: `Quins tipus d'edificis es poden gestionar amb aquesta eina?`,
    P_HERRAMIENTA_DIGITAL_DE: `l'eina digital de`,
    P_GESTION_INTEGRAL_EDIFICIOS: `gestió integral d'edificis`,
    P_QUE_TE_PERMITE_HACER: `Què et permet fer`,
    P_PARA_USUARIO_REGISTRADO_APLICACION: `Per a un usuari que s'ha registrat a l'aplicació per activar un edifici.`,
    P_PARA_INVITADO_EDIFICIO: `Per a un convidat a un edifici que se li ha assignat el seu codi personal d'accés.`,
    P_EJEMPLO_TEXTO_PRELIMINAR: `Exemple de text preliminar del Pla`,
    P_EJEMPLO_TEXTO_PRE: `
    <p>
        En aquest apartat s'introduirà el text preliminar del Pla. Si s'han emplenat les pantalles anteriors del Potencial de Millora i del Pla d'Actuacions, el Pla inclourà:
    </p>
    <ul>
        <li>
            Si és el cas, les actuacions urgents (conservació/reparació de danys) que hagin de realitzar-se.
        </li>
        <li>
            Si és el cas, indicacions per a la racionalització de l'ús i la gestió de l'edifici fins que es puguin fer les obres o actuacions de rehabilitació o renovació de l'edifici.
        </li>
        <li>
            Les mesures o conjunts de mesures que permetin aconseguir un estalvi tècnica i econòmicament viable en consum d'energia primària no renovable (Cep,nren) superior al 30%, indicant l'estalvi estimat en cada cas. La priorització d'actuacions tindrà en compte mesures o conjunts de mesures, almenys, per a cadascun dels tres nivells següents: reducció del consum d'energia primària no renovable entre el 30% i el 45%, entre el 45% i el 60%, i superior al 60%. En cas que no sigui possible aconseguir un estalvi en Cep,nren superior al 30%, s'haurà justificat aquesta impossibilitat, així com el valor màxim d'estalvi possible estimat.
        </li>
        <li>
            La reducció estimada del consum d'energia final (per vector energètic) total i per serveis, així com l'ús d'energies renovables.
        </li>
        <li>
            Les mesures o conjunts de mesures proposades per a tenir en compte la forma d'ocupació i l'ús de l'espai, així com la pròpia participació de les persones usuàries.
        </li>
        <li>
            Una valoració qualitativa o quantitativa de les millores sobre la resta de les prestacions de l'edifici, com la qualitat ambiental interior, les condicions de seguretat (d'ús o en cas d'incendi) o l'accessibilitat.
        </li>
        <li>
            La descripció, com a informació a l'usuari, dels avantatges pretesos amb la millora de les diferents prestacions derivada de l'execució de l'actuació o actuacions proposades.
        </li>
    </ul>
    `,
    P_ALTA_HACERLO_PROPIEDAD_REPRESENTANTE: `L'alta de l'edifici l'ha de fer la propietat o el seu representant legal.`,
    P_ENTIDADES_LOCAL_VIVIENDAS: `En cas que hi hagi entitats amb ús local la tarifa per entitat serà igual que la dels habitatges. Les entitats amb ús aparcament no tenen un cost associat i no s'han d'incloure a l'edifici virtual.`,
    PARRAFO_USARAN_ELEMENTOS_SELECCIONADOS: 'Es faran servir els elements seleccionats de la cerca actual',
    P_INFO_IMPORTAR_ITE: `El document a importar ha d'haver-se exportat del <a class="link-primary text-decoration-underline" href="https://ite.agenciahabitatge.cat/Ite/AppJava/views/login.xhtml" target="_blank" rel="noreferrer">Portal ITE de l'Agència de l'Habitatge de Catalunya</a> en format comprimit (.zip).`,
    P_NO_COMUNIDAD_AUTONOMA_CONTACTE: `Si la vostra comunitat autònoma no està en aquest llistat podeu posar-vos en contacte amb el Col·legi d'arquitectura tècnica de la vostra província.`,
    P_INSTRUCCIONES_REF_CATASTRAL_1: `Per donar d'alta un edifici cal incloure els 14 primers dígits de la referència cadastral, per a identificar una entitat concreta de l'edifici s'han de posar els 20 dígits.`,
    P_INSTRUCCIONES_REF_CATASTRAL_2: `El sistema es connectarà a la base de dades de cadastre, aquest procés pot trigar uns minuts.`,
    P_NIF_ASOCIADO_EXISTENTE_2: 'En cas de ser un usuari ja registrat i haver oblidat la contrasenya, cal restablir-la.',
    P_NIF_ASOCIADO_EXISTENTE_3: `En cas de tenir accés a un edifici amb codi, podeu acabar el registre mitjançant el procés de restablir contrasenya.`,
    P_ENTIDADES_EXCLUIR_APARCAMENTO: `Les úniques entitats jurídiques que es poden excloure són les d'ús aparcament, la resta hauran d'incorporar-se a l'edifici virtual i es tindran en compte per al càlcul de la subscripció.`,
    P_AL_GENERAR_PERIODICIDAD: `En ingressar la periodicitat, es generaran les activitats subseqüents fins a la data final del projecte.`,
    P_FORMULARIO_CONTACTO_ENVIADO: `El formulari de contacte s'ha enviat correctament. Ens posarem en contacte amb tu el més aviat possible.`,
    P_NUMERO_COLEGIADO_PRECIO_CONVENIO: `Per aplicar el preu reduït de col·legi en conveni, el número de col·legiat/da indicat en aquest apartat ha de coincidir amb l'informat al CGATE. En cas de dubte posa't en contacte amb el teu col·legi professional.`,
    P_DOCUMENTOS_NO_DEBEN_PROTEGIDOS: `Els documents que es vulguin incloure al Llibre de l'edifici no han d'estar bloquejats ni protegits amb contrasenya.`,
    P_DOCUMENTOS_NO_DEBEN_PROTEGIDOS_2: `Els documents que es vulguin incloure no han d'estar bloquejats ni protegits amb contrasenya.`,
    P_ENTIDADES_INTRODUCCION: `Introducció d'entitats jurídiques que conformen l'edifici`,
    P_EDITAR_PERIODO: `Si s'amplia la data final del període, es generaran les activitats restants fins la nova data final del període.`,
    P_IMPORTAR_ACTIVIDAD: `A l'ingressar la periodicitat, es generaran les activitats subsegüents fins la data de final del període.`,
    P_INDICA_PERIODOS: `Indica els períodes a incloure al Programa de rehabilitació`,
    P_TRANSFERIR_EDIFICIO: `Al realitzar aquesta acció es designarà a la persona indicada com a nou propietari de l'edifici virtual podent així seguir editant les dades. Amb aquesta acció l'antic propietari deixarà de poder accedir a l'edifici virtual ni consultar dades o documents.`,

}

export default PARRAFOS;